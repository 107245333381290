/*global angular: false */

let am = angular.module('mtz-directives');

am.directive('stateSelect', () => {
  let states = {
    AB: 'Alberta',
    BC: 'British Columbia',
    PE: 'Prince Edward Island',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland and Labrador',
    NT: 'Northwest Territories',
    NS: 'Nova Scotia',
    NU: 'Nunavut',
    ON: 'Ontario',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukon',
  };

  let template = '<select class="form-control state-select" ' +
    'ng-options="abbrev as name for (abbrev, name) in states">' +
    '</select>';

  return {
    restrict: 'AE',
    replace: true,
    require: '^ngModel',
    template: template,
    link(scope) {
      scope.states = states;
    }
  };
});
